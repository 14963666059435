<template>
  <div class="my-6">
    <BirdSection />
    <NavMenuSecondary />
  </div>
</template>

<script>
import BirdSection from "../components/newpetrecord/bird/BirdSection.vue";
import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    BirdSection,
    NavMenuSecondary
  },
};
</script>